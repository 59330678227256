/* eslint-disable */
/* global tns */
/* global gsap */
(
    function ($) {
        'use strict';

        var Site = {
            $body: $('html'),
            $searchContainer: $('.header .header__search'),
            $searchSuggestionsContainer: $('.header .search-suggestions'),

            $animateOnScroll: $('[data-animate]'),
            $blockHeroCircle: $('[data-hero-circle]'),

            $btnToTop: $('[data-scroll-to-top]'),
            $lightCase: $('a[data-rel^=lightcase]'),
            $socialShare: $('.social-share'),

            $language: $('[data-language]'),

            $slider: $('.slider'),
            $sliderLogos: $('.logos-slider'),
            $sliderProducts: $('.products-slider'),
            $sliderReviews: $('.reviews-slider'),
            $sliderGallery: $('.gallery-slider'),
            $sliderTogether: $('.together-slider'),
            $carouselEvents: $('.events-carousel'),

            $anchorNavigation: $('.navigation--anchor'),

            $responsiveTables: $('table:not(.not-responsive)'),
            $responsiveEmbedVideos: $('iframe[src*="youtube"], iframe[src*="vimeo"]'),

            /**
             * Toggle the search form in the header.
             *
             * @param element
             */
            onToggleSearchForm: function (element) {
                var $input = $('input', this.$searchContainer);

                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-search-active');

                if ($input.length) {
                    if (this.$body.hasClass('primary-search-active')) {
                        setTimeout($.proxy(function () {
                            $input.focus();
                        }, this), 500);
                    } else {
                        $input.blur();
                    }
                }

                return false;
            },

            /**
             * Toggle the language switch.
             *
             * @param element
             */
            onToggleLanguage: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$language.toggleClass('active');
                
                return false;
            },

            /**
             * Toggle the language switch.
             *
             * @param element
             */
            onClickLanguage: function (element) {
                return true;
            },

            /**
             * Check the search form for an empty value.
             *
             * @param element
             */
            onSubmitSearchForm: function (element) {
                var $input = $('input', $(element.currentTarget));

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    alert($(element.currentTarget).attr('data-alert'));

                    return false;
                }

                return true;
            },

            /**
             * Check the search form suggestions.
             *
             * @param element
             */
            onChangeSearchForm: function (element) {
                var value = $(element.currentTarget).val();

                if (value.length < 1) {
                    this.$searchSuggestionsContainer.html('').removeClass('active');

                    return;
                }

                $.ajax({
                    url: '/assets/components/simplesearch/connector.php?action=web/autosuggestions&search=' + value,
                    dataType: 'JSON',
                    complete: $.proxy(function (result) {
                        var $suggestions = [];

                        if (result.responseJSON.results.length) {
                            $(result.responseJSON.results).each(function (idx, suggestion) {
                                $suggestions.push($('<li>').append(
                                    $('<a href="#" title="' + suggestion + '">' + suggestion + '</a>').bind('click', $.proxy(function (event) {
                                        $('input', this.$searchContainer).val($(event.currentTarget).attr('title'));

                                        this.$searchContainer.submit();

                                        return false;
                                    }, this))
                                ));
                            });
                        }

                        if ($suggestions.length > 0) {
                            this.$searchSuggestionsContainer.html($suggestions).addClass('active');
                        } else {
                            this.$searchSuggestionsContainer.html($suggestions).removeClass('active');
                        }
                    }, this)
                });
            },

            /**
             * Toggle the main navigation.
             *
             * @param element
             */
            onToggleNavigation: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-navigation-active');

                return false;
            },

            /**
             * Toggle sub navigation in the main navigation.
             *
             * @param element
             */
            onToggleSubNavigation: function (element) {
                if (!$(element.currentTarget).parent('li').hasClass('subnav-active')) {
                    $(element.currentTarget).parent('ul').find('.subnav-active').removeClass('subnav-active');
                    $(element.currentTarget).parent('ul').find('.active').removeClass('active');
                }
                $(element.currentTarget).parent('li').toggleClass('subnav-active');
                
                return true;
            },

            /**
             * Scroll to the top of the page.
             */
            onHandleScrollToTop: function () {
                $('html,body').animate({
                    scrollTop: 0
                });

                return false;
            },

            /**
             * Toggle social share.
             *
             * @param element.
             */
            onToggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param element.
             */
            onHandleSocialShareUrl: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Click hash.
             *
             * @param element
             */
            onHandleHash: function (element) {
                var navigate = true;
                var $element = $('#' + element.currentTarget.hash.substr(1));

                if ($element.length) {
                    $('html, body').animate({
                        scrollTop : $element.offset().top
                    }, 500);

                    navigate = false;
                }

                return navigate;
            },

            /**
             * Toggle FAQ question.
             *
             * @param element
             */
            onToggleFaqQuestion: function (element) {
                $(element.currentTarget).parents('[data-faq-question]').toggleClass('active');

                return false;
            },

            /**
             * Detect window scroll set body classes and animate blocks.
             */
            initWindowScroll: function () {
                var $window         = $(window);
                var windowScroll    = 0;

                this.$animateOnScroll.each(function (i, element) {
                    $(element).addClass('is-loaded');
                });

                $window.scroll($.proxy(function () {
                    var currentScroll = $window.scrollTop();

                    this.$animateOnScroll.each($.proxy(function (i, element) {
                        var $element            = $(element);
                        var currentViewScroll   = currentScroll + $window.outerHeight();

                        if (!$element.hasClass('is-visible')) {
                            if (currentViewScroll >= $element.offset().top - 50) {
                                $element.addClass('is-visible');
                            }
                        }
                    }, this));

                    if (currentScroll <= 0) {
                        this.$body.removeClass(['scroll-up', 'scroll-down']);
                    } else if (currentScroll > windowScroll) {
                        this.$body.removeClass('scroll-up').addClass('scroll-down');
                    } else {
                        this.$body.addClass('scroll-up').removeClass('scroll-down');
                    }

                    this.$body.toggleClass('header-fixed', currentScroll > $('header').outerHeight());

                    this.$btnToTop.toggleClass('active', currentScroll > $window.height());

                    windowScroll = currentScroll;
                }, this)).trigger('scroll');
            },

            /**
             * Init Lightcase.
             */
            initLightCase: function () {
                this.$lightCase.lightcase({
                    showSequenceInfo        : false,
                    fullscreenModeForMobile : true,
                    swipe                   : true,
                    showTitle               : false
                });
            },

            /**
             * Init the default slider.
             */
            initSlider: function () {
                this.$slider.each(function (index, slide) {
                    tns({
                        container           : slide,
                        animateIn           : 'fade-in',
                        animateOut          : 'fade-out',
                        autoplay            : false,
                        autoplayTimeout     : 3500,
                        autoplayHoverPause  : true,
                        rewind              : true,
                        nav                 : true,
                        items               : 1,
                        navContainer   : '.slider-nav',
                        navAsThumbnails: true,
                        controls: false,
                        gutter: 0,
                        loop: false
                    });
                });
            },

            /**
             * Init the logos slider.
             */
            initSliderLogos: function () {
                this.$sliderLogos.each(function (index, slide) {
                    tns({
                        container       : slide,
                        controls        : true,
                        items           : 2,
                        loop            : false,
                        nav             : false,
                        responsive      : {
                            768             : {
                                items           : 3
                            },
                            990             : {
                                items           : 6
                            }
                        }
                    });
                });
            },

            /**
             * Init the products slider.
             */
            initSliderProducts: function () {
                this.$sliderProducts.each(function (index, slide) {
                    tns({
                        container   : slide,
                        items       : 1,
                        slideBy     : 1,
                        mouseDrag   : true,
                        controls    : true,
                        nav         : false,
                        gutter      : 0,
                        loop        : false,
                        responsive  : {
                            540         : {
                                items       : 2
                            },
                            768         : {
                                items       : 3
                            },
                            990         : {
                                items       : 4
                            }
                        }
                    });
                });
            },

            /**
             * Init the reviews slider.
             */
            initSliderReviews: function () {
                this.$sliderReviews.each(function (index, slide) {
                    tns({
                        container       : slide,
                        items           : 1,
                        animateIn       : 'fade-up',
                        animateOut      : 'fade-down',
                        loop            : false,
                        rewind          : true,
                        nav             : false
                    });
                });
            },

            /**
             * Init the gallery slider.
             */
            initSliderGallery: function () {
                this.$sliderGallery.each(function (index, slide) {
                    tns({
                        container       : slide,
                        items           : 1,
                        loop            : false,
                        rewind          : true,
                        nav             : true,
                        controls        : false,
                        mouseDrag       : false,
                        navContainer    : '.gallery-slider-nav',
                        navAsThumbnails : true
                    });
                });
            },

            /**
             * Init the logos slider.
             */
            initSliderTogether: function () {
                this.$sliderTogether.each(function (index, slide) {
                    tns({
                        container       : slide,
                        controls        : true,
                        items           : 1,
                        loop            : false,
                        nav             : false,
                        gutter          : 35,
                        responsive      : {
                            768             : {
                                items           : 2
                            },
                            992             : {
                                items           : 3
                            }
                        },
                        onInit          : function (slider) {
                            var width = 100 / slider.slideCount;
                            $(slider.container).find('.tns-item').css('width', 'calc(' + width + '% - 35px)');
                        }
                    });
                });
            },

            /**
             * Init the Events carousel.
             */
            initCarouselEvents: function () {
                this.$carouselEvents.each(function (index, slide) {
                    tns({
                        container   : slide,
                        items       : 1,
                        slideBy     : 1,
                        mouseDrag   : true,
                        controls    : true,
                        nav         : false,
                        gutter      : 0,
                        responsive  : {
                            540         : {
                                items       : 1
                            },
                            768         : {
                                items       : 2
                            },
                            990         : {
                                items       : 3
                            }
                        }
                    });
                });
            },

            /**
             * Submit filter form for categories
             * @param element
             */
            onSelectTagDropdown: function (element) {
                $(element.currentTarget).parent('form').trigger('submit');
            },

            /**
             * Init the anchor navigation.
             */
            initAnchorNavigation: function () {
                var $window = $(window);

                this.$anchorNavigation.each($.proxy(function (i, navigation) {
                    var $navigation         = $(navigation);
                    var $navigationItems    = $('li a', $navigation);
                    var $anchors            = [];
                    var $targets            = [];

                    $navigationItems.each($.proxy(function (ii, anchor) {
                        var $anchor  = $(anchor);
                        var $target  = $('[data-anchor="' + $anchor.attr('href').substr(1) + '"]');

                        if ($target.length) {
                            $anchor.on('click', $.proxy(function () {
                                $('html, body').animate({
                                    scrollTop : $target.offset().top - 80
                                }, 500);

                                $navigationItems.parent('li').removeClass('active');

                                $anchor.parent('li').addClass('active');

                                return false;
                            }, this));

                            $anchors.push($anchor);
                            $targets.push($target);
                        }
                    }, this));

                    $window.on('scroll', $.proxy(function () {
                        var scrollTop = $window.scrollTop();

                        $targets.forEach($.proxy(function ($target, ii) {
                            var offset = $target.offset().top - 100;
                            var height = $target.outerHeight();

                            if (scrollTop >= offset && scrollTop <= offset + height) {
                                $anchors[ii].parent('li').addClass('active');
                            } else {
                                $anchors[ii].parent('li').removeClass('active');
                            }
                        }, this));
                    }, this)).trigger('scroll');
                }, this));
            },

            /**
             * Init the responsive tables.
             */
            initResponsiveTables: function () {
                this.$responsiveTables.each(function (i, table) {
                    $(table).wrap('<div class="table-wrapper" />');
                });
            },

            /**
             * Init the responsive embed videos.
             */
            initResponsiveEmbedVideos: function () {
                this.$responsiveEmbedVideos.each(function (i, video) {
                    $(video).wrap('<div class="video-wrapper" />').wrap('<div class="video-wrapper__inner" />');
                });
            },

            /**
             * Init ingredients filters.
             */
            initFilters: function () {
                $('.filter-toggle').on('click', function () {
                    $(this).find('h4').toggleClass('hidden');
                    $(this).next('ul').toggle();
                });

                $('.ingredients__filter-toggle-btn, .ingredients__filter-close-btn').on('click', function () {
                    $('.ingredients__filter-toggle-btn').toggle();
                    $('#filters').slideToggle();
                });

                if ($('#filters').length > 0) {
                    $('#filters .checkbox input').on('change', this.filterChanged);
                    var parts = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                    for (var i = 0; i < parts.length; i++) {
                        var param = parts[i].split('=');
                        var key = param[0];
                        var value = decodeURIComponent(param[1]).split(',');
                        for (var j = 0; j < value.length; ++j) {
                            $('#filters .checkbox input[name="' + key + '"][value="' + value[j] + '"]').each(function () {
                                $(this).attr('checked', 'checked');
                                $(this).parent().addClass('checked');
                                $(this).parents('li').addClass('active');
                            });
                        }
                    }

                    var filterTotal = 0;
                    $('.selected-filters span').text(filterTotal);
                    $('#filters .checkbox input[checked]').each(function () {
                        filterTotal++;

                        var checkedTag = $('<a class="active">');
                        checkedTag.attr('data-name', $(this).attr('name'));
                        checkedTag.attr('data-value', $(this).val());
                        checkedTag.html($(this).next('label').html());

                        checkedTag.on('click', function () {
                            $(this).fadeOut('normal', function () {
                                $('#filters .checkbox input[name="' + $(this).data('name') + '"][value="' + $(this).data('value') + '"]').removeAttr('checked').trigger('change');
                            });
                        });
                        checkedTag.appendTo('.selected-filters');
                    });

                    if ($('#selected-ingredients').children('.ingredients__list-products-item').length === 0) {
                        if (typeof (window.NoResultsLexicon) === 'undefined') {
                            window.NoResultsLexicon = '<p>There are no results that match your selection, please make a other selection</p>';
                        }
                        $('#selected-ingredients').html(window.NoResultsLexicon);
                    }

                    $('.remove-filters').on({
                        click: function (e) {
                            e.preventDefault();
                            $('#filterform input[type=checkbox]').removeAttr('checked').trigger('change');
                        }
                    });
                }
            },

            filterChanged: function () {
                var checked = [];
                $('#filters input:checked').each(function () {
                    if (typeof checked[$(this).attr('name')] === 'undefined') {
                        checked[$(this).attr('name')] = [];
                    }
                    checked[$(this).attr('name')][checked[$(this).attr('name')].length] = $(this).val();
                });

                var url = $('#filters').data('url');
                Object.keys(checked).forEach(function (key) {
                    url += (url.indexOf('?') >= 0) ? '&' : '?';
                    url += key + '=' + checked[key].join(',');
                });
                window.location.href = url;
            },

            initIterestModal() {
                $(document).on('click', '.btn--interest', function () {
                    var $modal = $('#modal-interest');
                    if ($modal.length) {
                        $modal.find('.' + $modal.data('class') + ' > input').val($modal.data('title'));
                        $modal.modal();
                    }
                });
            },

            /**
             * Init tabs
             */
            initTabs: function () {
                $(document).on('click', '[data-tabs-target]', function () {
                    var target = '#' + $(this).data('tabs-target');
                    $(this).parents('.block').find('.tabs-panel').removeClass('is-active');
                    $(this).parents('.tabs').find('.tabs-title').removeClass('is-active');
                    $(this).parents('.tabs-title').addClass('is-active');
                    $(target).addClass('is-active');

                    return false;
                });
            },

            /**
             * Init animation hero
             */
            initAnimationHero: function () {
                this.$blockHeroCircle.each(function (i, element) {
                    var $trigger = $(element),
                        position = $(element).offset(),
                        posleft = -200;

                    gsap.set($trigger, {
                        x: position.left,
                        y: 0,
                        left: 0
                    });

                    if (i === 1) {
                        posleft = position.left - 200;
                    }

                    if (i === 2) {
                        posleft = (position.left * 2) + 100;
                    }

                    gsap.timeline({
                        scrollTrigger: {
                            trigger: '.block--hero',
                            scrub: 1,
                            start: 'top top',
                            end: '+=' + (window.innerHeight * 2)
                        }
                    }).to($trigger, { x: posleft, y: -$(window).outerHeight() - $('.header').outerHeight() });
                });
            },

            /**
             * Init animation hero
             */
            initAnimationFooterLogo: function () {
                gsap.set('.svg-brand', {
                    y: -60
                });

                gsap.set('.svg-or', {
                    rotate: 21
                });

                gsap.set('.svg-do', {
                    y: -70
                });


                gsap.set('.svg-it', {
                    y: -70
                });

                gsap.timeline({
                    defaults: {
                        ease: 'bounce.out',
                        duration: 1.5
                    },
                    scrollTrigger: {
                        trigger: '.footer',
                        start: 'center bottom',
                        end: 'bottom bottom'
                    }
                }).to('.svg-brand', { y: 0 }, 'start')
                    .to('.svg-or', { rotate: 0, delay: 0.1 }, 'start')
                    .to('.svg-do', { y: 0, delay: 0.1 }, 'start')
                    .to('.svg-it', { y: 0, delay: 0.2 }, 'start');
            },

            /**
             * Fix image widht when it's ratated
             */
            fixRotatedImg: function () {
                // Disabled this for a css solution
                // $('.block--text-leftright .highlights-overview__item img').each(function () {
                //     var width  = parseInt($(this).css('width'),  10);
                //     var height = parseInt($(this).css('height'), 10);
                //     if (width < height) {
                //         var value = $(this).css('transform');
                //         $(this).css({ transform: value + ' scale(.7)', margin: '-100px 0' });
                //     }
                // });
            },

            /**
             * Init team
             */
            initTeam: function () {
                /* align team rows */
                $('.team__list-person').removeClass('first-row');
                $('.team__list-person').removeClass('last-row');

                var totalPerson = $('.team__list-person:visible').length;
                $('.team__list-person:visible').each(function (i) {
                    /* each 7th should have margin-right */
                    if ((i + 1) % 7 === 0) {
                        $(this).addClass('last-row');
                    }
                    /**
                     * After row with 4 persons should be row with 3 persons,
                     * first of 3-row should have left margin.
                     * But only if it not last and not pre-last
                     */
                    if ((i + 1) % 7 === 5 && (totalPerson - i) > 2) {
                        $(this).addClass('first-row');
                    }
                });
            },

            /**
             * Toggle team
             */
            onToggleTeam: function (element) {
                $(element.currentTarget).toggleClass('active');
                $(element.currentTarget).parents('.team__selector').find('.team__selector-list').slideToggle();

                return false;
            },

            /**
             * Filter team
             */
            onFilterTeam: function (element) {
                $('.team__toggler').toggleClass('active');
                $('.team__selector-list a').removeClass('active');
                $('.team__selector-list').slideUp();

                if ($(element.currentTarget).data('team') === $('.team__toggler').data('selected')) {
                    $('.team__toggler').text($('.team__toggler').data('complete'));
                    $('.team__toggler').data('selected', '');
                    $('.team__list-person').show();
                } else {
                    $(element.currentTarget).addClass('active');
                    $('.team__toggler').text($(element.currentTarget).text());
                    $('.team__toggler').data('selected', $(element.currentTarget).data('team'));

                    $('.team__list-person').hide();
                    $('.filter-' + $(element.currentTarget).data('team')).show();
                }

                this.initTeam();

                return false;
            },

            /**
             * Fix styles for ActiveCampaign subscribe embed form
             */
            initSubscribeForm: function () {
                let form_id = null;

                if(!footer_activecampaignid) {
                    form_id = 1;
                } else {
                    form_id = footer_activecampaignid;
                }

                $('._form_' + form_id).addClass('subscribe-form').show();
                $('._form_' + form_id + ' style').remove();

                $('.subscribe-form input').addClass('form-control');
                $('.subscribe-form button').addClass('btn btn--green');

                $('._form_' + form_id + ' ._form_element').first().hide();
                $('._form_element').addClass('form-element');

                $('.subscribe-form ._button-wrapper').addClass('button-wrapper');
                $('.subscribe-form ._form-thank-you').addClass('form-thank-you');
            },

            /**
             * Fix styles for ActiveCampaign contact embed form
             */
            initContactForm: function () {
                let form_id = null;

                if(!contact_activecampaignid) {
                    form_id = 1;
                } else {
                    form_id = contact_activecampaignid;
                }

                $('._form_' + form_id).addClass('contact-form-ac').show();
                $('._form_' + form_id + ' style').remove();

                $('.contact-form-ac input').addClass('form-control');
                $('.contact-form-ac button').addClass('btn btn--green');

                $('._form_' + form_id + ' ._form_element').first().hide();
                $('._form_element').addClass('form-element');

                $('.contact-form-ac ._button-wrapper').addClass('button-wrapper');
                $('.contact-form-ac ._form-thank-you').addClass('form-thank-you');
            },

            /**
             * Add translate="no" to DO IT ORGANIC Brand name, fix by Andre v/d M
             */
            initPreventTranslate: function () {
                $('p').html(function (index, html) {
                    return html.replace('DO IT ORGANIC', '<span translate="no">DO IT ORGANIC</span>');
                });

                $('span').html(function (index, html) {
                    return html.replace('DO IT ORGANIC', '<span translate="no">DO IT ORGANIC</span>');
                });

                $('h1').html(function (index, html) {
                    return html.replace('DO IT ORGANIC', '<span translate="no">DO IT ORGANIC</span>');
                });

                $('h2').html(function (index, html) {
                    return html.replace('DO IT ORGANIC', '<span translate="no">DO IT ORGANIC</span>');
                });

                $('h3').html(function (index, html) {
                    return html.replace('DO IT ORGANIC', '<span translate="no">DO IT ORGANIC</span>');
                });
            },


            /**
             * Init call.
             */
            init: function () {
                this.initWindowScroll();

                this.initLightCase();
                this.initAnimationHero();
                this.initAnimationFooterLogo();

                this.initSlider();
                this.initSliderLogos();
                this.initSliderProducts();
                this.initSliderReviews();
                this.initSliderGallery();
                this.initSliderTogether();

                this.initCarouselEvents();

                this.initAnchorNavigation();

                this.initResponsiveTables();
                this.initResponsiveEmbedVideos();

                this.fixRotatedImg();

                this.initFilters();
                this.initTabs();
                this.initIterestModal();
                this.initTeam();
                this.initSubscribeForm();
                this.initContactForm();

                $(window).resize(function () {
                    Site.initAnimationHero();
                });

                this.initPreventTranslate();
            }
        };

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchForm')
        }, '.search-toggle');

        $(document).on({
            submit: $.proxy(Site, 'onSubmitSearchForm')
        }, '.header .form-search');

        $(document).on({
            keyup: $.proxy(Site, 'onChangeSearchForm')
        }, 'header .form-search input[type="text"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleNavigation')
        }, '.nav-toggle');

        $(document).on({
            click: $.proxy(Site, 'onToggleSubNavigation')
        }, '.has-subnav .toggle-subnav, .has-subnav > a');

        $(document).on({
            click: $.proxy(Site, 'onClickLanguage')
        }, '.specific-language');

        $(document).on({
            click: $.proxy(Site, 'onToggleLanguage')
        }, '[data-language-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onHandleScrollToTop')
        }, '[data-scroll-to-top]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSocialShare')
        }, '[data-social-share-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onHandleSocialShareUrl')
        }, '[data-social-share-url]');

        $(document).on({
            click: $.proxy(Site, 'onHandleHash')
        }, 'a[href*="#"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFaqQuestion')
        }, '[data-faq-question-toggle]');

        $(document).on({
            change: $.proxy(Site, 'onSelectTagDropdown')
        }, '[data-tag-dropdown]');

        $(document).on({
            click: $.proxy(Site, 'onToggleTeam')
        }, '.team__toggler');

        $(document).on({
            click: $.proxy(Site, 'onFilterTeam')
        }, '.team__selector-list a');

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
